.card-container {
    display: flex;
  }
  
  .card {
    margin-right: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .card img {
    width: 150px;
    height: auto;
  }
  
  .cta-buttons button {
    margin-top: 10px;
  }
  